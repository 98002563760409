<template>
  <base-section id="k-d-a-technology-list-detail" space="0">
    <div v-for="(item, id) in m_arrTechnology" :key="id">
      <k-d-a-technology-card
        :str-technology="item.m_strTechnology"
        :str-description="item.m_strDescription"
        :str-link="item.m_strLink"
        :img-technology="item.m_imgTechnology"
        :num-index="id"
        :str-link-detail="item.m_strLinkDetail"
      />
    </div>
  </base-section>
</template>

<script>
import Projects from '@/mixins/projects';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDATechListDetail',

  components: {
    KDATechnologyCard: () => import('@/components/KDATechnologyCard')
  },

  mixins: [Projects, TextClass],

  computed: {
    m_arrTechnology() {
      return [
        {
          m_strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.title'),
          m_strDescription: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.desc'),
          m_strLink: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.link'),
          m_imgTechnology: require('@/assets/photos/tech-list-1.png'),
          m_strLinkDetail: 'unreal-engine'
        },
        {
          m_strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.title'),
          m_strDescription: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.desc'),
          m_strLink: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.link'),
          m_imgTechnology: require('@/assets/photos/tech-list-2.png'),
          m_strLinkDetail: 'my-sql'
        },
        {
          m_strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.title'),
          m_strDescription: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.desc'),
          m_strLink: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.link'),
          m_imgTechnology: require('@/assets/photos/tech-list-3.png'),
          m_strLinkDetail: 'django-python'
        },
        {
          m_strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.unity.title'),
          m_strDescription: this.$vuetify.lang.t('$vuetify.kda.technology.unity.desc'),
          m_strLink: this.$vuetify.lang.t('$vuetify.kda.technology.unity.link'),
          m_imgTechnology: require('@/assets/photos/tech-list-4.png'),
          m_strLinkDetail: 'unity'
        },
        {
          m_strTechnology: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.title'),
          m_strDescription: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.desc'),
          m_strLink: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.link'),
          m_imgTechnology: require('@/assets/photos/tech-list-6.png'),
          m_strLinkDetail: 'keda-engine'
        }
      ];
    }
  }
};
</script>
